import {
  // useCallback,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import axios from 'src/utils/axios';
import { useSnackbar } from 'src/components/snackbar';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';

// @mui
import {
  Stack,
  Dialog,
  Button,
  // TextField,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  // RadioGroup,
  // FormControlLabel,
  // Radio,
  Typography,
  styled,
  Checkbox,
} from '@mui/material';
import { useAuthContext } from 'src/auth/useAuthContext';
// import label from 'src/components/label';
import FileThumbnail from 'src/components/file-thumbnail';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, {
  // RHFRadioGroup,
  RHFTextField,
} from 'src/components/hook-form';
// components
import Editor from 'src/components/editor';
// import Iconify from '../../components/iconify';
// import { Upload } from '../../components/upload';
import SelectTemplate from './SelectTemplate';

// ----------------------------------------------------------------------

interface Props extends DialogProps {
  open: boolean;
  onClose: VoidFunction;
  job: any;
  attachments: any;
  action: any;
  toAddress: string;
  setToAddress: (value: string) => void;
  ccAddress1: string;
  setCCAddress: (value: string) => void;
  draftMessage: string;
  emailSubject1: string;
  setEmailSubject: (value: string) => void;
}

type FormValuesProps = {
  EmailSubject: string;
  AddressTo: string;
  CCAddress: string;
  JobRecordID?: string;
};

interface EmailInfo {
  EmailRecordID: number;
  EmailMsgID: number;
  EmailFolderID: number;
  EmailSubject: string;
  EmailSummary: string;
  EmailHasAttach: number;
  EmailFlagID: string;
  EmailSender: string;
  EmailToAddress: string;
  EmailCCAddress: string;
  EmailLabelID: number;
  EmailReceivedTime: number;
  EmailStatus: number;
  EmailStatus2: number;
  ThreadCount?: number | null;
  ThreadID?: number | null;
  EmailIsJob: string;
  EmailCreatedAt: string;
  EmailIsProcessed: string;
  EmailProcessedAt: string;
  EmailJSON: any;
}

export default function SendEmailDialog({
  open,
  onClose,
  job,
  attachments,
  action,
  toAddress,
  setToAddress,
  ccAddress1,
  setCCAddress,
  draftMessage,
  emailSubject1,
  setEmailSubject,
}: Props) {
  const { user } = useAuthContext();

  const [data, setData] = useState<EmailInfo | null>(null);

  const [files, setFiles] = useState<(File | string)[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (!open) {
      setFiles([]);
    }
  }, [open]);

  // const CommentSchema = Yup.object().shape({
  //   EmailSubject: Yup.string().required('Email Subject is required'),
  //   AddressTo: Yup.string().required('Address To  is required'),
  //   CCAddress: Yup.string(),
  // });
  // const defaultValues = {
  //   EmailSubject: '',
  //   AddressTo: '',
  //   CCAddress: '',
  // };
  const methods = useForm<FormValuesProps>({
    // resolver: yupResolver(CommentSchema),
    // defaultValues,
  });
  const { getValues } = useForm<FormValuesProps>(); //
  const {
    // reset,
    handleSubmit,
    setValue,
    watch,
    // formState: { isSubmitting },
  } = methods;
  // const values = watch()

  const fetchEmailInfoById = useCallback(async () => {
    if (!job?.EmailRecordID) return;
    try {
      const response = await axios.get(`/core/getEmailInfoById?Id=${job?.EmailRecordID}`);
      const emailData = response.data;

      console.log('Fetched email data:', emailData);
      setData(emailData as EmailInfo);

      if (!emailData.EmailToAddress && !emailData.EmailSender) {
        setValue('AddressTo', '');
      } else if (!emailData.EmailToAddress) {
        setValue('AddressTo', emailData.EmailSender);
      } else {
        setValue('AddressTo', `${emailData.EmailToAddress}, ${emailData.EmailSender}`);
      }
      if (emailData?.EmailSubject) {
        setValue('EmailSubject', emailData?.EmailSubject);
      }

      if (emailData?.EmailCCAddress) {
        setValue('CCAddress', emailData.EmailCCAddress);
      }
      setEditor(emailData?.EmailSummary);
    } catch (error) {
      console.error('Error fetching getEmailInfoById:', error);
    }
  }, [job?.EmailRecordID, setData, setValue]);

  const getDraftMessage = useCallback(
    async (jobRecordId: any, userId: string, action1: string) => {
      try {
        setisLoading(true);

        const response = await axios.get(`/core/getMessages`, {
          params: {
            JobRecordID: jobRecordId,
            UserID: userId,
            Action: action1,
          },
        });
        const draftMessage1 = response.data;
        if (draftMessage1) {
          setValue('EmailSubject', draftMessage1.EmailSubject);
          setValue('AddressTo', draftMessage1.AddressTo);
          setValue('CCAddress', draftMessage1.CCAddress);
          setEditor(draftMessage1.draft_message);
        }
        return draftMessage1;
      } catch (error) {
        // Check if the error status is not 404 before showing the error message
        if (error.response && error.response.status !== 404) {
          enqueueSnackbar(error.response?.data?.error || 'Failed to retrieve draft message', {
            variant: 'error',
          });
        } else {
          console.log('No draft message found for the given parameters.');
        }
        return null;
      } finally {
        setisLoading(false);
        //  setSendMail(true); // Open the popup regardless of data availability
      }
    },
    [enqueueSnackbar, setValue]
  );

  // useEffect(() => {
  //   if (job?.JobRecordID && user?.ID) {
  //     getDraftMessage(job.JobRecordID, user.ID, 'Send Email to Client');
  //   }
  // }, [getDraftMessage, job?.JobRecordID, user?.ID]);

  const emailSubject = watch('EmailSubject');
  const addressTo = watch('AddressTo');
  const ccAddress = watch('CCAddress');

  useEffect(() => {
    const fetchData = async () => {
      console.log('Getting email data ...');
      if (job?.JobRecordID && user?.ID) {
        try {
          const draft_response = await getDraftMessage(
            job.JobRecordID,
            user.ID,
            action
          );
          // console.log('Draft response = ', draft_response);
          if (!draft_response || Object.keys(draft_response).length === 0) {
            console.log('Draft response is empty, fetching email info by ID...');
            fetchEmailInfoById();
          }
        } catch (error) {
          console.error('Error fetching draft message:', error);
        }
      } else {
        fetchEmailInfoById();
      }
    };

    fetchData();
  }, [action, fetchEmailInfoById, getDraftMessage, job?.JobRecordID, user?.ID]);

  function formatFileSize(bytes: any) {
    if (bytes === 0) return '0 Bytes';

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / 1024 ** i).toFixed(2))} ${sizes[i]}`;
  }
  const StyledRoot = styled('div')(({ theme }) => ({
    position: 'relative',
    padding: theme.spacing(0, 2),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.neutral,
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
    '&:hover': {
      zIndex: 9,
      position: 'relative',
      // boxShadow: theme.customShadows.z24,
      '& #mailActions': { opacity: 1 },
    },
  }));
  const [isloading, setisLoading] = useState(false);
  /* const onSubmit = async (dat: FormValuesProps) => {
    try {
      setisLoading(true);
      console.log('Data to send mail = ', editor);
      // if (user?.UserRole !== 'Assessor') {
      //   await CommentSchema.validate(dat, { abortEarly: false });
      // }
      const formData = new FormData();
      formData.append('AttachmentId', JSON.stringify(selectedAttachments));
      formData.append(`EmailSubject`, dat.EmailSubject);
      const styledEditor = editor.replace(
        /<p>/g,
        '<p style="font-family: Calibri; font-size: 10.5pt; padding: 0; margin: 0;">'
      );
      const styledEditor1 = `<p style="font-family: Calibri; font-size: 10.5pt; padding: 0; margin: 0;">${styledEditor}</p>`;
      formData.append(`EmailContent`, styledEditor1);
      formData.append(`AddressTo`, dat.AddressTo);
      formData.append(`CCAddress`, dat.CCAddress);
      formData.append(`JobRecordID`, job.JobRecordID);
      formData.append(`JobEmailID`, job.JobEmailMsgID);
      formData.append(`EntryBy`, user?.ID);
      console.log('Data to send mail = ', dat.AddressTo);

      if (editor === '') {
        enqueueSnackbar('Email Content is Required', { variant: 'error' });
        setisLoading(false);
        // return;
      }
      if (user?.UserRole !== 'Assessor') {
        if (dat.AddressTo === '') {
          enqueueSnackbar('To Address Is Required', { variant: 'error' });
          // } else if (dat.CCAddress === '') {
          //   enqueueSnackbar('CC Address Is Required', { variant: 'error' });
        } else if (dat.EmailSubject === '') {
          enqueueSnackbar('Email Subject Is Required', { variant: 'error' });
        } else if (editor !== '') {
          setisLoading(true);
          axios
            .post(`/core/sendMail`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              enqueueSnackbar(response.data);
              setEditor('');
              setValue('AddressTo', '');
              setValue('CCAddress', '');
              onClose();
              setisLoading(false);
            })
            .catch((error) => {
              enqueueSnackbar(error.error, { variant: 'error' });
              setisLoading(false);
            });
        }
      } else if (editor !== '') {
        setisLoading(true);
        axios
          .post(`/core/sendMail`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            enqueueSnackbar(response.data);
            setEditor('');
            setValue('AddressTo', '');
            setValue('CCAddress', '');
            onClose();
            setisLoading(false);
          })
          .catch((error) => {
            enqueueSnackbar(error.error, { variant: 'error' });
            setisLoading(false);
          });
      }
    } catch (error) {
      console.error(error);
      setisLoading(false);
    }
  }; */
  const mapFormValuesToEmailInfo = (formValues: FormValuesProps): Partial<EmailInfo> => ({
    EmailRecordID: 0, // Default or fetched value
    EmailMsgID: 0, // Default or fetched value
    EmailFolderID: 0, // Default or fetched value
    EmailCCAddress: formValues.CCAddress,
    EmailToAddress: formValues.AddressTo,
    EmailSubject: formValues.EmailSubject,
    EmailSummary: formValues.EmailSubject,
  });
  const actionTypeRef = useRef<'send' | 'draft' | null>(null);
  const onSubmit = async (data1: FormValuesProps) => {
    try {
      setisLoading(true);
      console.log('Data to send mail = ', editor);

      // Map FormValuesProps to EmailInfo
      const emailData: Partial<EmailInfo> = mapFormValuesToEmailInfo(data1);
      setData(emailData as EmailInfo);

      if (editor === '') {
        enqueueSnackbar('Email Content is Required', { variant: 'error' });
        setisLoading(false);
        return;
      }

      // Validate fields based on user role
      if (user?.UserRole !== 'Assessor') {
        if (!data1.AddressTo) {
          enqueueSnackbar('To Address Is Required', { variant: 'error' });
          setisLoading(false);
          return;
        }
        console.log('address to value for send mail', data1.AddressTo);
        if (!data1.EmailSubject) {
          enqueueSnackbar('Email Subject Is Required', { variant: 'error' });
          setisLoading(false);
          return;
        }
      }
      // Check which action is to be performed
      if (actionTypeRef.current === 'send') {
        const formData = createFormData(data1); // Assuming createFormData converts to FormData if needed
        await sendMail(data1);
      } else if (actionTypeRef.current === 'draft') {
        await saveDraft(data1); // Passing data as FormValuesProps
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to send mail', { variant: 'error' });
    } finally {
      setisLoading(false);
    }
  };

  // Helper to create FormData for both sendMail and saveDraft
  const createFormData = (dat: FormValuesProps) => {
    console.log('Data passed to createFormData:', dat);
    const formData = new FormData();
    formData.append('AttachmentId', JSON.stringify(selectedAttachments));
    formData.append('EmailSubject', dat?.EmailSubject || '');
    const styledEditor = editor.replace(
      /<p>/g,
      '<p style="font-family: Calibri; font-size: 10.5pt; padding: 0; margin: 0;">'
    );
    formData.append(
      'EmailContent',
      `<p style="font-family: Calibri; font-size: 10.5pt;">${styledEditor}</p>`
    );
    formData.append('AddressTo', dat.AddressTo || '');
    formData.append('CCAddress', dat.CCAddress || '');
    formData.append('JobRecordID', job?.JobRecordID || '');
    formData.append('JobEmailID', job?.JobEmailMsgID || '');
    formData.append('EntryBy', user?.ID || '');
    console.log('form data values of ccaddress', dat.CCAddress);
    return formData;
  };

  const sendMailRequest = async (formData: FormValuesProps | FormData) => {
    try {
      // Assuming axios is being used to send the data
      await axios.post(
        `/core/sendMail`,
        formData instanceof FormData ? formData : createFormData(formData),
        {
          headers:
            formData instanceof FormData ? { 'Content-Type': 'multipart/form-data' } : undefined,
        }
      );
      // Show success message with snackbar
      enqueueSnackbar('Email sent successfully!', { variant: 'success' });
      resetForm();
      // After successfully "sending" the email, delete the draft message
      await deleteDraftMessage(job?.JobRecordID, user?.ID, action);
      console.log('Draft message deleted after sending email');
    } catch (error) {
      console.error('Failed to send mail:', error);
    }
  };
  // Call this function from both the "Send Mail" and "Save As Draft" buttons
  const handleButtonClick = async (actionType: 'send' | 'draft') => {
    actionTypeRef.current = actionType;

    if (actionType === 'send') {
      await handleSubmit(onSubmit)();
    } else if (actionType === 'draft') {
      const formValues: FormValuesProps = {
        EmailSubject: emailSubject,
        AddressTo: addressTo,
        CCAddress: ccAddress,
        JobRecordID: job?.JobRecordID || '',
      };
      await saveDraft(formValues);
    }
  };

  const sendMail = async (formValues: FormValuesProps) => {
    const formData = createFormData(formValues);
    // Check if UserRole is not 'Management' or 'Workflow'
    if (user?.UserRole !== 'Management' && user?.UserRole !== 'Workflow') {
      formData.set('AddressTo', ''); // Update AddressTo 
      formData.set('CCAddress', ''); // Update CCAddress
    }
    await sendMailRequest(formData);
  };

  const saveDraft = async (formValues: FormValuesProps) => {
    const formData = createFormData(formValues);
    formData.append('Action', action);
    formData.append('DraftMessage', editor);
    formData.append('UserID', user?.ID || '');
    console.log('form data values in save draft function', formData);

    try {
      setisLoading(true);
      await axios.post(`/core/draftMessage`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      enqueueSnackbar('Draft saved successfully!', { variant: 'success' });
      // resetForm();
    } catch (error) {
      enqueueSnackbar(error.response?.data?.error || 'Failed to save draft', { variant: 'error' });
    } finally {
      setisLoading(false);
    }
  };
  // Helper to reset form fields
  const resetForm = () => {
    setEditor('');
    setValue('AddressTo', '');
    setValue('CCAddress', '');
    setValue('EmailSubject', ''); // Resetting EmailSubject if necessary
    onClose();
  };
  const [selectedAttachments, setSelectedAttachments] = useState<any>([]);

  const handleCheckboxChange = (jobAttachID: any) => {
    const isSelected = selectedAttachments.includes(jobAttachID);
    console.log(selectedAttachments);
    if (isSelected) {
      setSelectedAttachments((prevSelected: any) =>
        prevSelected.filter((id: any) => id !== jobAttachID)
      );
    } else {
      setSelectedAttachments((prevSelected: any) => [...prevSelected, jobAttachID]);
    }
  };

  const [editor, setEditor] = useState('');
  // useEffect(() => {
  //   if (draftMessage) {
  //     setEditor(draftMessage);
  //     console.log("Draft message value:", draftMessage);
  //     console.log("Editor content:", editor);
  //   }
  // }, [draftMessage]);
  // Initialize editor content with draftMessage
  useEffect(() => {
    if (draftMessage) {
      setEditor(draftMessage);
    }
  }, [draftMessage]);
  const onValueChanges = (value: any) => {
    setEditor(value);
  };

  const deleteDraftMessage = useCallback(
    async (jobRecordId: any, userId: string, deleteAction: string) => {
      try {
        setisLoading(true);

        // Make DELETE request to the API
        const response = await axios.delete(`/core/deleteDraftMessage`, {
          params: {
            JobRecordID: jobRecordId,
            UserID: userId,
            Action: deleteAction,
          },
        });
        console.log("response data of delete draft",response)
        // Check if the deletion was successful and a record was found to delete
        if (response.status === 200) {
//          enqueueSnackbar('Draft message deleted successfully!', { variant: 'success' });
          console.log('Draft message deleted successfully!')
          // Clear draft-related states after deletion
          setEmailSubject('');
          setEditor('');
          setToAddress('');
          setCCAddress('');
          // setEmailInput('');
        } else {
          console.log('No draft message found to delete for the given parameters.');
        }
        console.log('Draft message deletion response:', response.data);

        return response.data;
      } catch (error) {
        // Show error message if deletion fails
        if (error.response && error.response.status !== 404) {
          // enqueueSnackbar(error.response?.data?.error || 'Failed to delete draft message', {
          //   variant: 'error',
          // });
          console.log('Failed to delete draft message 404.');
        } else {
          console.log('No draft message found to delete for the given parameters.');
        }
        return null;
      } finally {
        setisLoading(false);
      }
    },
    [setEmailSubject, setEditor, setToAddress, setCCAddress]
  );
  const [template, setTemplate] = useState(false);

  const openTemplateSelector = () => {
    setTemplate(true);
  };
  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}> Send Email </DialogTitle>

      <SelectTemplate
        open={template}
        onClose={() => {
          setTemplate(false);
        }}
        setEditor={onValueChanges}
        job_data={job}
      />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
          <Stack spacing={2}>
            {/* <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel> */}
            {user?.UserRole !== 'Assessor' && (
              <>
                <RHFTextField name="AddressTo" label="Address To" sx={{ width: '100%' }} />
                <RHFTextField name="CCAddress" label="CC Address" sx={{ width: '100%' }} />
                <RHFTextField name="EmailSubject" label="Email Subject" sx={{ width: '100%' }} />
              </>
            )}

            <Button
              variant="text"
              onClick={() => {
                openTemplateSelector();
              }}
            >
              {' '}
              Select Template
            </Button>
            <Editor value={editor} onChange={onValueChanges} onchan={setEditor} />

            <Typography variant="subtitle2" noWrap>
              Select attachments to Send Email to Requestor/Builder
              {/* Note : Minimum 1  attachment to be selected */}
            </Typography>
            {attachments &&
              attachments.map((list: any, key: any) => (
                <StyledRoot sx={{ bgcolor: 'background.paper' }}>
                  <Checkbox
                    checked={selectedAttachments.includes(list.JobAttachLoc)}
                    onChange={() => handleCheckboxChange(list.JobAttachLoc)}
                  />
                  <Stack
                    key={key}
                    spacing={2}
                    direction="row"
                    alignItems="center"
                    sx={{
                      my: 1,
                      px: 1,
                      py: 0.75,
                    }}
                  >
                    <FileThumbnail file={list.JobAttachExtension} />

                    <Stack flexGrow={1} sx={{ minWidth: 0 }}>
                      <Typography variant="subtitle2" noWrap>
                        {list.JobAttachName}
                      </Typography>
                      <Typography variant="subtitle2" noWrap>
                        {formatFileSize(list.JobAttachSize)}
                      </Typography>
                    </Stack>
                  </Stack>
                </StyledRoot>
              ))}
          </Stack>
        </DialogContent>

        <DialogActions>
          {/* <Button variant="contained" color="secondary"   onClick={() => handleButtonClick('draft')} disabled={isloading}>
          Save As Draft
          </Button>
          <LoadingButton variant="contained" loading={isloading} type ="submit">
         {/*  onClick={() => handleButtonClick('send') */}
          {/*  Send Mail
          </LoadingButton> */}
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleButtonClick('draft')}
            disabled={isloading}
          >
            Save As Draft
          </Button>
          <LoadingButton
            variant="contained"
            loading={isloading}
            onClick={() => handleButtonClick('send')}
          >
            Send Mail
          </LoadingButton>

          <Button variant="outlined" color="secondary" onClick={onClose} disabled={isloading}>
            Cancel
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
